import Container from 'components/container'
import Layout from 'components/layout'
import Card from 'components/molecules/card'
import Seo from 'components/molecules/seo'
import {
  ArticleGridItem,
  ArticlesGrid,
} from 'components/organisms/articlesList'
import { graphql, PageProps } from 'gatsby'
import { getLocalNodes } from 'helpers/locale'
import { useResourceRichTextData } from 'hooks/useResourceRichTextData'
import { useResourceTextData } from 'hooks/useResourceTextData'
import * as React from 'react'
import { useState } from "react"
import styled from "styled-components"
import { CategoryHeader } from 'templates/communityCategoryTemplate'
import {
  ContentfulJobConnection,
  ContentfulResourceTextConnection,
  Site,
} from 'types/graphql-types'

import Select from "../components/atoms/select"

type IndexTypes = PageProps & {
  data: {
    site: Site
    allContentfulJob: ContentfulJobConnection
    allContentfulResourceSet: ContentfulResourceTextConnection
  }
}

const StyledSelect = styled(Select)`
  margin-bottom: 1rem;
`

const RootIndex = ({ data, ...props }: IndexTypes): JSX.Element => {
  const jobData = getLocalNodes(data.allContentfulJob.nodes)

  const options = [
    {
      name: 'Global',
      value: '',
    },
    {
      name: 'New Zealand',
      value: 'New Zealand',
    },
    {
      name: 'Australia',
      value: 'Australia',
    },
    {
      name: 'United Kingdom',
      value: 'United Kingdom',
    },
    {
      name: 'Singapore',
      value: 'Singapore',
    },
    {
      name: 'Japan',
      value: 'Japan',
    },
  ]

  const [selectedFilter, setSelectedFilter] = useState(options[0].value)

  return (
    <Layout>
      <Seo
        title={useResourceTextData('careers.metaTitle', 'Careers in Coffee')}
        postTitle={useResourceTextData(
          'careers.metaPostTitle',
          'Work With Us | Allpress Espresso'
        )}
        description={useResourceTextData(
          'careers.metaDescription',
          'Careers in the coffee industry. We recruit for a range of roles from brand & marketing to machine technicians, barista trainers and front of house staff.'
        )}
        url={props.location.href}
      />

      <CategoryHeader>
        <h1>{useResourceTextData('careers.pageTitle', 'Work with us')}</h1>
        <div>{useResourceRichTextData('careers.leadingParagraph')}</div>
      </CategoryHeader>
      <Container>
        <StyledSelect
          value={selectedFilter}
          onChange={(e): void => setSelectedFilter(e.currentTarget.value)}
        >
          {options.map(o => (
            <option key={o.name} value={o.value}>{o.name}</option>
          ))}
        </StyledSelect>

      </Container>

      <Container>
        <ArticlesGrid>
          {jobData.filter(job => selectedFilter !== '' ? job?.region?.includes(selectedFilter) : true)
            .map((job, i) => {
            return (
              <ArticleGridItem key={job.id} uniform>
                <Card
                  title={job.title}
                  slug={`/careers/${job.slug}/`}
                  subTitle={{
                    title: job.location,
                  }}
                  image={job.heroImage}
                  i={i}
                />
              </ArticleGridItem>
            )
          }
          )}
        </ArticlesGrid>
      </Container>
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query CareersQuery {
    allContentfulJob {
      nodes {
        id
        title
        slug
        location
        region
        node_locale
        heroImage {
          fluid {
            ...Image
          }
        }
      }
    }
  }
`
