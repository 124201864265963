import ChevDown from 'images/svg/chev-down.svg'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

const StyledSelect = styled.div<{ outline?: boolean }>`
  ${({ theme, outline }): CSSProp => css`
    position: relative;
    display: inline-block;
    select {
      appearance: none;
      display: block;
      background: ${theme.colours.secondary};
      border: ${outline ? `solid #E3D5C4 1px` : 'none'};
      background-color: ${outline ? 'none' : theme.colours.secondary};
      border-radius: 2px;
      padding: 1.8rem 4rem 1.8rem 2rem;
      width: 100%;
      font-size: 1.4rem;
      &.error {
        box-shadow: 0px 0px 0px 1px red;
      }
    }
    svg {
      height: 0.7rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 2rem;
      pointer-events: none;
    }
  `}
`

type SelectProps = {
  children: React.ReactNode
  onChange?: (e: React.FormEvent<HTMLSelectElement>) => void
  defaultValue?: string
  value?: string
  outline?: boolean
}

const Select = ({ children, outline, ...rest }: SelectProps): JSX.Element => (
  <StyledSelect className="select" outline={outline}>
    <select {...rest}>{children}</select>
    <ChevDown />
  </StyledSelect>
)

export default Select
