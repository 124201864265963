import { graphql, useStaticQuery } from 'gatsby'
import { ContentfulNavigationConnection } from 'types/graphql-types'

export const useCommunityNavigationData = (): ContentfulNavigationConnection => {
  const { allContentfulNavigation } = useStaticQuery(
    graphql`
      query communityNavigation {
        allContentfulNavigation(filter: { title: { eq: "Community" } }) {
          ...ContentfulNavigationNodes
        }
      }
    `
  )

  return allContentfulNavigation
}
