import { renderDocumentToReactComponents } from 'components/organisms/renderDocumentToReactComponents'
import { graphql, useStaticQuery } from 'gatsby'

import { getLocalNodes } from '../helpers/locale'
export const useResourceRichTextData = (key: string, fallBack?: string): string => {
  const { allContentfulResourceRichText } = useStaticQuery(
    graphql`
      query resourceRichText {
        allContentfulResourceRichText {
          nodes {
            id
            key
            node_locale
            value {
              raw
              references {
                ... on Node {
                  ... on ContentfulRegionalLink {
                    contentful_id
                    title
                    nzSlug
                    auSlug
                    ukSlug
                    sgSlug
                    jpSlug
                    internal {
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  )

  const resourceTextLocal = getLocalNodes(allContentfulResourceRichText.nodes)
  const filteredResources = resourceTextLocal.filter((r) => {
    return r.key === key
  })

  return filteredResources[0]?.value && renderDocumentToReactComponents(filteredResources[0]?.value) || fallBack
}
