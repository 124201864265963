import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

import { getLocalNodes } from '../../helpers/locale'
import { useCommunityNavigationData } from '../../hooks/useCommunityNavigationData'
import Mag from '../../images/svg/mag.svg'
import NavigationList from '../molecules/navigation-list'

const CommunityNavWrapper = styled.div`
  ${({ theme }): CSSProp => css`
    display: flex;
    align-items: center;
    padding: 0 ${theme.padding.mobileDefault};
    text-transform: uppercase;
    font-size: ${theme.font.fontSizeSmall};
    letter-spacing: ${theme.font.fontLetterSpacingNav};
    margin-bottom: 3.5rem;
    background-color: ${theme.colours.lightTint3};
    .scroller {
      overflow-x: auto;
      overflow-scrolling: touch;
      flex: 1;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        padding: 0 1rem;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 4rem;
        }

        a {
          display: block;
          text-decoration: none;
          border-top: 0.2rem solid transparent;
          border-bottom: 0.2rem solid transparent;
          padding: 0.2rem 0;
          margin: 1.6rem 0;
          white-space: nowrap;

          &.active {
            border-bottom: 0.2rem solid ${theme.colours.primary};
          }
        }
      }
    }
    .search {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        right: 100%;
        top: 0;
        width: 100px;
        height: 100%;
        background: linear-gradient(to left, #e1d2c4, rgba(225, 210, 196, 0));
        pointer-events: none;
      }
      button {
        border: none;
        background: none;
        cursor: pointer;
      }
    }
    svg {
      position: relative;
      top: 2px;
      width: 1.6rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        width: 2.2rem;
      }
    }
  `}
`

const CommunityNav = ({ toggleSearch }): JSX.Element => {
  const navigation = useCommunityNavigationData()
  const navigationLocal = getLocalNodes(navigation.nodes)
  const { navigationItems } = navigationLocal[0]

  return (
    <CommunityNavWrapper>
      <div className="scroller">
        <NavigationList navigationItems={navigationItems} />
      </div>
      <div className="search">
        <button type="button" onClick={toggleSearch}>
          <Mag />
        </button>
      </div>
    </CommunityNavWrapper>
  )
}

export default CommunityNav
